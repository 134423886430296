import { SanityDocument } from '@sanity/client';
import useOptimisticArray from '../../utils/useOptimisticArray';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import * as style from './OpeningHours.module.less';

export interface OpeningHoursProps extends SanityDocument {
    title: string;
    openingHours: ({
        _key: string;
        days: string;
    } & (
        | {
              isClosed: true;
          }
        | {
              isClosed: false;
              open: string;
              close: string;
          }
    ))[];
}

export default function OpeningHours({ _id, _type, title, openingHours }: OpeningHoursProps) {
    const dataAttr = useDataAttribute();
    const dataAttr2 = dataAttr?.combine({ id: _id, type: _type, path: [] });
    const list = useOptimisticArray(openingHours, 'openingHours', { _id, path: [] });
    return (
        <div className={style.openingHours} data-sanity={dataAttr?.([])}>
            <h3>{title}</h3>
            <ul data-sanity={dataAttr2?.(['openingHours'])}>
                {list?.map(({ _key, days, ...p }) => (
                    <li key={_key} data-sanity={dataAttr2?.(['openingHours', { _key }])}>
                        <span>{days}</span>
                        <span>{p.isClosed ? 'Stengt' : `${p.open} - ${p.close}`}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
