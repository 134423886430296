import type { PortableTextMarkComponentProps } from '@portabletext/react';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import ClientReactRoot from '../../utils/ClientReactRoot';
import stopPropagation from '../../utils/stopPropagation';
import useBooleanState from '../../utils/useBooleanState';
import usePopoverInsideScreen from '../../utils/usePopoverInsideScreen';
import Grape from '../grid/Grape';
import groq from '../infrastructure/groq';
import * as style from './richText.module.less';

export const grapeMarkGroq = groq`
{
    _type,
    _key,
    grape -> {
        'slug': slug.current,
        name,
        summary,
        grapeType,
        'hasText': count(description),
        mainEntry -> {
            'slug': slug.current,
            name,
            summary,
            'hasText': count(description),
        }
    },
    'page': *[_type == 'listPage' && listType == 'grape'][0]{
        '_ref': _id,
        _type
    }
}
`;

interface Grape {
    slug?: string;
    name?: string;
    summary?: string;
    grapeType: 'green' | 'blue';
    hasText: boolean | null;
    mainEntry: {
        slug?: string;
        name?: string;
        summary?: string;
        hasText: boolean | null;
    };
}

interface GrapePopupProps {
    _type: 'grapeMark';
    grape?: Grape;
    page?: {
        path?: string;
    };
}

export function GrapeMark({ value, children }: PortableTextMarkComponentProps<GrapePopupProps>) {
    if (!value) return children;

    return (
        <ClientReactRoot as="span">
            <GrapePopup value={value}>{children}</GrapePopup>
        </ClientReactRoot>
    );
}

export function GrapePopup({ value, children }: React.PropsWithChildren<{ value: GrapePopupProps }>) {
    const [isOpen, setIsOpen] = useBooleanState();
    const ref = useRef<HTMLSpanElement>(null);

    useOnClickOutside(ref, setIsOpen.toFalse);

    const grape = value.grape;
    if (!grape) return null;

    return (
        <span onClick={setIsOpen.toggle} className={style.popup} ref={ref}>
            {isOpen && <Popup page={value.page?.path} grape={grape} />}
            {children}
        </span>
    );
}

function Popup({ page, grape }: { page?: string; grape: Grape }) {
    const tooltipRef = usePopoverInsideScreen<HTMLDivElement>('var(--side-paddings)');
    return (
        <div className={style.tooltip} ref={tooltipRef} onClick={stopPropagation()}>
            <Grape name={grape.name} summary={grape.summary || grape.mainEntry?.summary} grapeType={grape.grapeType} />
            {!!grape.hasText ? (
                <a href={`${page}#${grape.slug}`}>Vil du lese mer om {grape.name}?</a>
            ) : !!grape.mainEntry?.hasText ? (
                <a href={`${page}#${grape.mainEntry.slug}`}>Vil du lese mer om {grape.mainEntry.name}?</a>
            ) : null}
        </div>
    );
}
