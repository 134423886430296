import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../components/ArticleInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import { RelatedPages, relatedPagesGroq, RelatedPagesProps } from '../components/RelatedPages';
import { BannerImage, imageWithMetadataGroq } from '../components/SanityImage';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface InformationArticle extends SanityPageDocument {
    _type: 'informationArticle';
    richText: any;
    hideBannerImage: boolean;
    relatedPages: RelatedPagesProps;
}

export const informationArticle = queryable<InformationArticle>(groq`
    *[_type == "informationArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        hideBannerImage,
        richText[] ${richTextGroq},
        relatedPages ${relatedPagesGroq}
    }
`);

export default SanityPage<InformationArticle>(({ page, breadcrumbs }) => (
    <>
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
            </header>
            <BannerImage image={page.hideBannerImage ? undefined : page.bannerImage} aspectRatio={2.7} />
            <section>
                <RichText value={page.richText} />
                <ArticleInfo lastUpdated={page._updatedAt} />
            </section>
            <RelatedPages {...page.relatedPages} />
        </article>
    </>
));
