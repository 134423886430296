import { FacetData, FacetGroup } from '../model/Facets';
import SortSelector, { SortValue } from '../sortSelector/SortSelector';
import { trackLinkClick } from '../utils/trackLinkClick';
import FacetButton from './FacetButton';
import SelectedFacets from './SelectedFacets';

export interface ContentSearchFacetBarProps {
    facets: Pick<FacetData, 'any' | 'selectedFacets'>;
    onClickFilter(): void;
}

export default function ContentSearchFacetBar({ facets, onClickFilter }: ContentSearchFacetBarProps) {
    if (!facets.any) return null;

    return (
        <div className="search-controls--filters no-print">
            <FacetButton onClick={onClickFilter} title={`Endre filtre (${facets.selectedFacets.length})`} />
        </div>
    );
}

export interface ProductSearchFacetBarProps {
    facets: FacetData;
    onClickFilter(): void;
    onFacetValueUnselect(query: string): void;
    onSingleFacetChoice(facetValue: FacetGroup): void;
}

export function ProductSearchFacetBar({ facets, onClickFilter, onSingleFacetChoice, onFacetValueUnselect }: ProductSearchFacetBarProps) {
    return (
        <div className="search-controls--filters no-print">
            <SelectedFacets selectedFacets={facets.selectedFacets} onFacetValueUnselect={onFacetValueUnselect} />
            {facets.groupedFacets.slice(0, Math.max(5 - facets.selectedFacets.length, 0)).map((facet) => (
                <FacetButton key={facet.name} title={facet.name} onClick={() => onSingleFacetChoice(facet)} selectable />
            ))}
            <FacetButton onClick={onClickFilter} title="Flere filtre" />
        </div>
    );
}

export interface SearchTypeAndSortBarProps {
    selectedSearch: 'product' | 'content';
    totalContentResults?: number;
    totalProductResults?: number;
    query: string;
    sortValues?: SortValue[];
    onSortSelect?(oldCode: string, newCode: string): void;
}

export function SearchTypeAndSortBar({
    selectedSearch,
    totalContentResults,
    totalProductResults,
    query,
    sortValues,
    onSortSelect,
}: SearchTypeAndSortBarProps) {
    return (
        <div className="search-controls--options">
            <div className="search-controls--result-types" onClick={trackLinkClick}>
                {selectedSearch === 'product' ? (
                    <span className="selected">{totalProductResults} varer</span>
                ) : (
                    <a href={`/search?q=${query}`}>Se varer</a>
                )}
                {selectedSearch === 'content' ? (
                    <span className="selected">{totalContentResults} artikler</span>
                ) : (
                    <a href={`/search/content?q=${query}`}>Les artikler</a>
                )}
            </div>
            <SortSelector values={sortValues} onValueSelect={onSortSelect} />
        </div>
    );
}
