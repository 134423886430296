import type { PortableTextMarkComponentProps } from '@portabletext/react';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import ClientReactRoot from '../../utils/ClientReactRoot';
import stopPropagation from '../../utils/stopPropagation';
import useBooleanState from '../../utils/useBooleanState';
import usePopoverInsideScreen from '../../utils/usePopoverInsideScreen';
import Definition from '../grid/Definition';
import groq from '../infrastructure/groq';
import * as style from './richText.module.less';

export const definitionMarkGroq = groq`
{
    _type,
    _key,
    definition -> {
        'slug': slug.current,
        name,
        summary,
        'hasText': count(description),
        mainEntry -> {
            'slug': slug.current,
            name,
            summary,
            'hasText': count(description),
        }
    },
    'page': *[_type == 'listPage' && listType == 'definition'][0]{
        '_ref': _id,
        _type
    }
}
`;

interface DefinitionProps {
    slug?: string;
    name?: string;
    summary?: string;
    hasText: boolean | null;
    mainEntry?: {
        slug?: string;
        name?: string;
        summary?: string;
        hasText: boolean | null;
    };
}

interface DefinitionPopupProps {
    _type: 'definitionMark';
    definition?: DefinitionProps;
    page?: {
        path?: string;
    };
}

export function DefinitioMark({ value, children }: PortableTextMarkComponentProps<DefinitionPopupProps>) {
    if (!value) return children;

    return (
        <ClientReactRoot as="span">
            <DefinitionPopup value={value}>{children}</DefinitionPopup>
        </ClientReactRoot>
    );
}

export function DefinitionPopup({ value, children }: React.PropsWithChildren<{ value: DefinitionPopupProps }>) {
    const [isOpen, setIsOpen] = useBooleanState();
    const ref = useRef<HTMLSpanElement>(null);

    useOnClickOutside(ref, setIsOpen.toFalse);

    const definition = value.definition;
    if (!definition) return null;

    return (
        <span onClick={setIsOpen.toggle} className={style.popup} ref={ref}>
            {isOpen && <Popup page={value.page?.path} definition={definition} />}
            {children}
        </span>
    );
}

function Popup({ page, definition }: { page?: string; definition: DefinitionProps }) {
    const tooltipRef = usePopoverInsideScreen<HTMLDivElement>('var(--side-paddings)');
    return (
        <div className={style.tooltip} ref={tooltipRef} onClick={stopPropagation()}>
            <Definition name={definition.name} summary={definition.summary} />
            {!!definition.hasText ? (
                <a href={`${page}#${definition.slug}`}>Vil du lese mer om {definition.name}?</a>
            ) : !!definition.mainEntry?.hasText ? (
                <a href={`${page}#${definition.mainEntry.slug}`}>Vil du lese mer om {definition.mainEntry.name}?</a>
            ) : null}
        </div>
    );
}
