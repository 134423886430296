import storeIcon from '../../../images/frontpage/frontpage-store.svg';
import useOptimisticArray from '../../utils/useOptimisticArray';
import useDataAttribute, { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { Keyed } from '../types';
import OpeningHours, { OpeningHoursProps } from './OpeningHours';
import * as style from './StoreOpeningHours.module.less';

export interface StoreOpeningHoursSchema {
    _type: 'storeOpeningHours';
    _key: string;
    title: string;
    openingHours: Keyed<OpeningHoursProps>[];
    notice: string;
}

export interface StoreOpeningHoursProps {
    _type: 'storeOpeningHours';
    title: string;
    openingHours: Keyed<OpeningHoursProps>[];
    notice: string;
}

export const storeOpeningHoursGroq = groq`{
    _key,
    _type,
    title,
    openingHours[] {
        _key,
        ...(@->)
    },
    notice
}`;

export default function StoreOpeningHours({ title, openingHours, notice }: StoreOpeningHoursProps) {
    const dataAttr = useDataAttribute();

    const list = useOptimisticArray(openingHours, 'openingHours');

    return (
        <div data-sanity={dataAttr?.([])} className={style.container}>
            <img src={storeIcon} />
            <h2>{title}</h2>
            <div className={style.openingHours} data-sanity={dataAttr?.(['openingHours'])}>
                {list?.map(({ _key, ...props }) => (
                    <ScopeDataAttribute key={_key} path={['openingHours', { _key }]}>
                        <OpeningHours {...props} />
                    </ScopeDataAttribute>
                ))}
            </div>
            <div>
                <p>{notice}</p>
                <a href="/finn-butikk" className="btn btn-secondary">
                    Sjekk åpningstidene for ditt lokale Vinmonopol
                </a>
            </div>
        </div>
    );
}
