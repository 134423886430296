import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';

export function VimeoVideo({ value }: PortableTextComponentProps<any>) {
    if (!value?.vimeoUrl) return null;

    const videoUrl = getUrl(value.vimeoUrl);

    if (!videoUrl) return null;

    return (
        <div className="video-embed">
            <iframe src={videoUrl} title={value.title} allowFullScreen></iframe>
        </div>
    );
}

function getUrl(vimeoUrl: string) {
    const [, id, h] = vimeoUrl.match(/vimeo\.com\/(\d+)\/?(\w+)?/) ?? [];
    if (id && h) {
        return `https://player.vimeo.com/video/${id}?h=${h}`;
    } else if (id) {
        return `https://player.vimeo.com/video/${id}`;
    } else {
        return null;
    }
}

export const videoEmbedGroq = groq`
{
    _key,
    _type,
    title,
    vimeoUrl
}
`;
