import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import groq from '../infrastructure/groq';
import * as style from './WineCountryItem.module.less';

export type WineCountryItemProps = {
    _id: string;
    path: string;
    _type: 'wineCountryArticle';
    title: string;
    bannerImage: ImageWithMetadata;
    ingress: string;
    districts: WineDistrictProps[];
};

type WineDistrictProps = {
    _type: 'wineDistrictArticle';
    path: string;
    title: string;
    _id: string;
    slug: string;
    subdistricts: WineSubDistrictProps[];
};

type WineSubDistrictProps = {
    _type: 'wineDistrictArticle';
    title: string;
    _id: string;
    path: string;
};

export const wineCountryItemGroq = groq`{
    _id,
    _type,
    title,
    ingress,
    bannerImage ${imageWithMetadataGroq},
    "districts": *[_type == "wineDistrictArticle" && parent._ref == ^._id] {
        _id,
        title,
        slug,
        "subdistricts": *[_type == "wineDistrictArticle" && parent._ref == ^._id] {
            _id,
            title,
            slug
        }
    }
}`;

const imageWidths = [94, 94 * 2, 165, 165 * 2];
export function WineCountryItem({ title, ingress, districts, bannerImage, path }: WineCountryItemProps) {
    return (
        <div className={style.wineCountryItem}>
            <SanityImage image={bannerImage} sizes="(min-width: 568px) 165px, 94px" widths={imageWidths} aspectRatio={1} />
            <details>
                <summary>
                    <div className={style.info}>
                        <h2>{title}</h2>
                        <p>{ingress}</p>
                        <a href={path}>
                            Les om <span>{title}</span>
                        </a>
                    </div>
                </summary>
                {!!districts?.length && (
                    <ul>
                        {districts?.map((district) => (
                            <li key={district._id} className={style.district}>
                                <a href={district.path}>{district.title}</a>
                                {!!district.subdistricts?.length && (
                                    <ul>
                                        {district.subdistricts?.map((subdistrict) => (
                                            <li className={style.subdistrict} key={subdistrict._id}>
                                                <a href={subdistrict.path}>{subdistrict.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </details>
        </div>
    );
}
