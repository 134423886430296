import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../components/ArticleInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContactCard } from '../components/ContactCard';
import { BannerImage, imageWithMetadataGroq } from '../components/SanityImage';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface NewsPage extends SanityPageDocument {
    _type: 'newsArticle';
    firstPublished: string;
    richText: any;
    pressContact?: {
        email: string;
        name: string;
        phone: string;
    };
}

export const newsArticle = queryable<NewsPage>(groq`
    *[_type == "newsArticle" && _id == $id][0]{
        _id,
        _type,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        firstPublished,
        richText[] ${richTextGroq},
        pressContact -> {
            email,
            name,
            phone
        }
    }
`);

export default SanityPage<NewsPage>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={2.7} />
        <section>
            <RichText value={page.richText} />
            {page.pressContact && <ContactCard name={page.pressContact.name} email={page.pressContact.email} phone={page.pressContact.phone} />}
            <ArticleInfo firstPublished={page.firstPublished} />
        </section>
    </article>
));
