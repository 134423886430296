import { PortableTextComponentProps } from '@portabletext/react';
import groq from '../infrastructure/groq';
import * as style from './richText.module.less';
interface Podcast {
    _key: string;
    episodeId: string;
    show: string;
}

export const podcastGroq = groq`{
    episodeId,
    show
}`;

export function Podcast({ value }: PortableTextComponentProps<Podcast>) {
    const { episodeId, show } = value;
    const bgColor = '002025'; //@color-swamp
    const accentColor = 'ffffff';
    const fontFamily = 'Roboto';
    const fontSrc = encodeURIComponent('https://fonts.googleapis.com/css?family=Roboto');
    const src = `https://embed.acast.com/${show}/${episodeId}?accentColor=${accentColor}&bgColor=${bgColor}&font-family=${fontFamily}&font-src=${fontSrc}`;
    return (
        <div className={style.podcast}>
            <iframe src={src} />
        </div>
    );
}
