import { RefCallback } from 'react';
import { createCallable } from 'react-call';
import Modal from '../generic/Modal';
import { deleteTrackingCookies, setConsentCookie } from '../utils/cookies';
import { useOnMount } from '../utils/useComponentDidMount';
import * as style from './CookieConsentModal.module.less';

const CookieConsentModal = createCallable(({ call }) => {
    const consentLimited = () => {
        setConsentCookie(false);
        deleteTrackingCookies();
        call.end();
        navigator.sendBeacon('/api/consent/limited');
    };

    const consentAll = () => {
        setConsentCookie(true);
        call.end();
        navigator.sendBeacon('/api/consent/all');
    };

    useOnMount(() => {
        navigator.sendBeacon('/api/consent/shown');
    });

    return (
        <Modal isOpen size="small" padding>
            <h2>Informasjonskapsler</h2>

            <div className={style.text}>
                <p>Vi bruker informasjonskapsler for å forbedre brukeropplevelsen på vår nettside.</p>
                <p>Informasjonskapslene har ulike formål: noen er nødvendige, mens andre er funksjonelle eller brukes til analyse.</p>
                <p>
                    <a href="/informasjonskapsler">Besøk vår informasjonsside</a> for mer detaljer, eller finn lenken i bunnen av nettsiden.
                </p>
            </div>

            <div className={style.buttons}>
                <button className="btn-secondary" onClick={consentLimited} ref={autoFocus}>
                    Godkjenn nødvendige
                </button>
                <button className="btn-primary" onClick={consentAll}>
                    Godkjenn alle
                </button>
            </div>
        </Modal>
    );
});

export default CookieConsentModal;

// This is a hack since there is a bug in React: https://github.com/facebook/react/issues/23301
const autoFocus: RefCallback<HTMLButtonElement> = (e) => setTimeout(() => e?.focus(), 10);
