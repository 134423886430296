import { SanityDocument } from '@sanity/client';
import SanityImage, { ImageWithMetadata } from '../components/SanityImage';
import * as style from './SimpleGridItem.module.less';

export interface SimpleGridItemProps<T extends string = string> extends SanityDocument<{ _type: T }> {
    path?: string;
    title?: string;
    ingress?: string;
    bannerImage?: ImageWithMetadata;
}
const imageWidths = [84, 84 * 2];
export default function SimpleGridItem({ path, title, bannerImage }: SimpleGridItemProps) {
    return (
        <a href={path} className={style.simpleGridItem}>
            <h2>{title}</h2>
            <SanityImage image={bannerImage} widths={imageWidths} aspectRatio={1} />
        </a>
    );
}
