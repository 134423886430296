import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../components/ArticleInfo';
import ArticleTags from '../components/ArticleTags';
import Breadcrumbs from '../components/Breadcrumbs';
import { FaqArray, FaqList, faqListGroq } from '../components/Faq';
import { RelatedPages, relatedPagesGroq, RelatedPagesProps } from '../components/RelatedPages';
import { BannerImage, imageWithMetadataGroq } from '../components/SanityImage';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface DrinkRecommendationPage extends SanityPageDocument {
    _type: 'drinkRecommendationArticle';
    richText: any;
    disclaimer?: {
        title?: string;
        richText?: any;
    };
    faq: FaqArray;
    relatedPages: RelatedPagesProps;
}

export const drinkRecommendationArticle = queryable<DrinkRecommendationPage>(groq`
    *[_type == "drinkRecommendationArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        richText[] ${richTextGroq},
        authors[] -> {
            name,
            role
        },
        tags[] -> {
            ...
        },
        disclaimer -> {
            ...
        },
        faq[]-> ${faqListGroq},
        relatedPages ${relatedPagesGroq}
    }
`);

export default SanityPage<DrinkRecommendationPage>(({ page, breadcrumbs }) => (
    <>
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
                <ArticleInfo authors={page.authors} />
            </header>
            <BannerImage image={page.bannerImage} aspectRatio={16 / 9} />
            <ArticleTags tags={page.tags} />
            <section>
                <RichText value={page.richText} />
                <FaqList items={page.faq} />
                <ArticleInfo lastUpdated={page._updatedAt} wineMagazineEdition={page.wineMagazineEdition} />
            </section>
            <RelatedPages {...page.relatedPages} />
        </article>
    </>
));
