import Icon from '../../generic/Icon';
import { RichText } from '../richTextComponents';
import { Keyed } from '../types';
import * as styles from './Accordion.module.less';

export interface AccordionGroupProps {
    title?: string;
    items?: Keyed<AccordionProps>[];
}

export function AccordionGroup({ title, items }: AccordionGroupProps) {
    if (!items?.length) return null;
    return (
        <div className={styles.accordionGroup}>
            {!!title && <h2 className="lined-heading">{title}</h2>}
            {items.filter(Boolean).map(({ title, text, _key }) => (
                <Accordion key={_key} title={title} text={text} />
            ))}
        </div>
    );
}

export interface AccordionProps {
    title: string;
    text: any;
    studioPath?: string;
}

export function Accordion({ title, text, studioPath }: AccordionProps) {
    return (
        <details className={styles.accordion}>
            <summary>
                <span>{title}</span>
                <Icon className="icon-details"></Icon>
            </summary>
            <div>
                <RichText value={text} studioPath={studioPath} />
            </div>
        </details>
    );
}
