import groq from '../infrastructure/groq';
import { definitionMarkGroq } from './DefinitionMark';
import { externalLinkGroq } from './externalLink';
import { fileComponentHyperlinkGroq } from './fileComponent';
import { grapeMarkGroq } from './GrapeMark';
import { internalLinkGroq } from './internalLink';

export const markDefsGroq = groq`{
    ...,
    _type == "link" => ${externalLinkGroq},
    _type == "internalLink" => ${internalLinkGroq},
    _type == "grapeMark" => ${grapeMarkGroq},
    _type == "definitionMark" => ${definitionMarkGroq},
    _type == "fileComponent" => @->${fileComponentHyperlinkGroq},
}`;
