import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../components/ArticleInfo';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContactCard } from '../components/ContactCard';
import { FaqArray, FaqList, faqListGroq } from '../components/Faq';
import { BannerImage, imageWithMetadataGroq } from '../components/SanityImage';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface CustomerServiceArticle extends SanityPageDocument {
    _type: 'customerServiceArticle';
    richText: any;
    contact?: {
        email: string;
        name: string;
        phone: string;
    };
    faq: FaqArray;
}

export const customerServiceArticle = queryable<CustomerServiceArticle>(groq`
    *[_type == "customerServiceArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        richText[] ${richTextGroq},
        contact -> {
            email,
            name,
            phone
        },
        faq[]-> ${faqListGroq},
    }
`);

export default SanityPage<CustomerServiceArticle>(({ page, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <BannerImage image={page.bannerImage} aspectRatio={2.7} />
        <section>
            <RichText value={page.richText} />
            <FaqList items={page.faq} />
            {page.contact && <ContactCard {...page.contact} />}
            <ArticleInfo lastUpdated={page._updatedAt} />
        </section>
    </article>
));
