import * as styles from './AlphabetList.module.less';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅ'.split('');

export const AlphabetList = ({ list }: { list: string[] }) => (
    <div className={styles.alphabet}>
        {alphabet.map((letter) =>
            list?.some((n) => n?.toUpperCase()?.startsWith(letter)) ? (
                <a key={letter} href={`#${letter.toLowerCase()}`}>
                    {letter}
                </a>
            ) : (
                <span key={letter}>{letter}</span>
            ),
        )}
    </div>
);
