import { SanityReference, stegaClean } from '@sanity/client/stega';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { ImageFormat, SanityAsset, SanityImageCrop, SanityImageHotspot } from '@sanity/image-url/lib/types/types';
import { StudioPathLike } from '@sanity/react-loader';
import cn from 'classnames';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import useDataAttribute from '../infrastructure/DataAttributeContext';
import { useImageUrlBuilderContext } from '../infrastructure/ImageUrlBuilderContext';

export const imageWithMetadataGroq = /* groq */ `{
    ...,
    asset-> {
        _id,
        url,
        extension,
        altText,
        creditLine
    }
}`;

export interface ImageWithMetadata {
    asset?: (SanityReference | SanityAsset) & {
        title?: string;
        altText?: string;
        creditLine?: string;
        extension?: string;
    };
    crop?: SanityImageCrop;
    hotspot?: SanityImageHotspot;
    description?: string;
}

export interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    image?: ImageWithMetadata;
    /**
     * The sizes this image will have on the page for different media queries.
     *
     * For example: `(min-width: 1440px) 1440px, 100vw`
     * * When the viewport is above 1440px the image will be 1440px wide,
     * * when the viewport is below that it will be the size of the viewport
     *
     */
    sizes?: string;
    /**
     * A list of widths in pixels that the image might be loaded in.
     * You should specify at least any width specified in sizes and double that, for
     * 2x dpr devices
     */
    widths?: number[];
    aspectRatio?: number;
    formats?: ImageFormat[] | ImageFormat;
}

export default function SanityImage({ image, sizes, formats = ['webp', 'jpg'], widths, aspectRatio, style, ...props }: Props) {
    const builder = useImageUrlBuilderContext();
    if (!image?.asset) return null;
    const src = builder.image(image);
    const isSvg = stegaClean(image.asset.extension) === 'svg';
    return (
        <picture>
            {isSvg ? null : Array.isArray(formats) ? (
                formats.map((format) => (
                    <source key={format} type={`image/${format}`} srcSet={toSrcSet(src, format, widths, aspectRatio)} sizes={sizes} />
                ))
            ) : (
                <source key={formats} type={`image/${formats}`} srcSet={toSrcSet(src, formats, widths, aspectRatio)} sizes={sizes} />
            )}
            <img
                src={src.url()}
                style={{
                    aspectRatio,
                    ...style,
                }}
                alt={stegaClean(image.asset.altText)}
                {...props}
            />
        </picture>
    );
}

export function CaptionedSanityImage({ studioPath, float, ...props }: Props & { studioPath?: StudioPathLike; float?: 'left' | 'right' }) {
    const attr = useDataAttribute();
    if (!props.image?.asset) return null;
    return (
        <figure data-sanity={studioPath && attr?.(studioPath)} className={cn({ float }, float)}>
            <SanityImage {...props} />
            <Caption creditLine={props.image.asset.creditLine} description={props.image.description} />
        </figure>
    );
}

function Caption({ creditLine, description }: { creditLine?: string; description?: string }) {
    if (!creditLine && !description) return null;
    return (
        <figcaption>
            {description} <strong>Foto:</strong> {creditLine}
        </figcaption>
    );
}

const bannerImageWidths = [320, 640, 1024, 1288];
export function BannerImage({ image, aspectRatio }: { image?: ImageWithMetadata; aspectRatio?: number }) {
    return (
        <CaptionedSanityImage
            image={image}
            widths={bannerImageWidths}
            aspectRatio={aspectRatio}
            sizes="(min-width: 1288) 1288px, 100vw"
            studioPath="bannerImage"
        />
    );
}

function toSrcSet(src: ImageUrlBuilder, format: ImageFormat, widths?: number[], aspectRatio?: number) {
    return widths
        ?.map((w) => `${(aspectRatio ? src.height(Math.floor(w / aspectRatio)).fit('crop') : src).width(w).format(format).url()} ${w}w`)
        .join(', ');
}
