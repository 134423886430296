import Icon from '../../generic/Icon';

type ContactCardProps = {
    name?: string;
    email?: string;
    phone?: string;
};

export function ContactCard({ name, email, phone }: ContactCardProps) {
    return (
        <address className="contact-card">
            <h2>{name}</h2>
            {email && (
                <>
                    <Icon className="icon-email" />
                    <p>
                        <a href={`mailto:${email}`}>{email}</a>
                    </p>
                </>
            )}
            {phone && (
                <>
                    <Icon className="icon-phone" />
                    <p>
                        <a href={`tel:${phone}`}>{phone}</a>
                    </p>
                </>
            )}
        </address>
    );
}
