import CookieConsentContainer from '../cookieConsentModal/CookieConsentContainer';
import ToastContainer from '../generic/Toast/ToastContainer';
import LoggedOutModal from '../login/LoggedOutModal';
import LoginNeededModal from '../login/LoginNeededModal';

export default function Callables() {
    return (
        <>
            <LoggedOutModal.Root />
            <ToastContainer />
            <LoginNeededModal.Root />
            <CookieConsentContainer />
        </>
    );
}
