import { SanityDocument } from '@sanity/client';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { ArticleInfo } from '../components/ArticleInfo';
import ArticleTags from '../components/ArticleTags';
import Breadcrumbs from '../components/Breadcrumbs';
import { FaqArray, FaqList, faqListGroq } from '../components/Faq';
import { RelatedPages, relatedPagesGroq, RelatedPagesProps } from '../components/RelatedPages';
import { BannerImage, CaptionedSanityImage, ImageWithMetadata, imageWithMetadataGroq } from '../components/SanityImage';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';

interface Recipe extends SanityDocument {
    _key: string;
    recipeImage?: ImageWithMetadata;
    name: string;
    chef?: {
        name?: string;
        role?: string;
    };
    servings: number;
    multistep?: boolean;
    recipes?: {
        _key: string;
        name: string;
        ingredients?: {
            _key: string;
            name: string;
            quantity: number;
            comment?: string;
        }[];
        richText: any;
    }[];
    ingredients?: {
        _key: string;
        name: string;
        quantity: number;
        comment?: string;
    }[];
    richText: any;
}

interface RecipePage extends SanityPageDocument {
    _type: 'recipeArticle';
    wineMagazineEdition?: {
        releaseNumber: string;
        pdf?: {
            size: number;
            url: string;
        };
    };
    richText: any;
    recipe?: Recipe;
    authors?: {
        name?: string;
    }[];
    showFaq?: boolean;
    faq: FaqArray;
    relatedPages: RelatedPagesProps;
}

export const recipeArticle = queryable<RecipePage>(groq`
    *[_type == "recipeArticle" && _id == $id][0]{
        _id,
        _type,
        _updatedAt,
        hidden,
        slug,
        title,
        ingress,
        metadata,
        bannerImage ${imageWithMetadataGroq},
        wineMagazineEdition -> {
            releaseNumber,
            'pdf': pdf.asset -> {
                size,
                url
            }
        },
        richText[] ${richTextGroq},
        authors[] -> {
            name
        },
        tags[] -> {
            ...
        },
        recipe -> {
            _id,
            _type,
            recipeImage ${imageWithMetadataGroq},
            name,
            chef -> {
                name,
                role
            },
            servings,
            multistep,
            recipes[] {
                _key,
                name,
                ingredients[] {
                    _key,
                    name,
                    quantity,
                    comment
                },
                richText[] ${richTextGroq}
            },
            ingredients[] {
                _key,
                name,
                quantity,
                comment
            },
            richText[] ${richTextGroq}
        },
        showFaq,
        faq[] -> ${faqListGroq},
        relatedPages ${relatedPagesGroq}
    }
`);

export default SanityPage<RecipePage>(({ page, breadcrumbs }) => (
    <>
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
                <ArticleInfo authors={page.authors} chef={page.recipe?.chef} />
            </header>
            <BannerImage image={page.bannerImage} aspectRatio={16 / 9} />
            <ArticleTags tags={page.tags} />

            <section>
                <RichText value={page.richText} />

                {!!page.recipe && (
                    <ScopeDataAttribute id={page.recipe._id} type={page.recipe._type}>
                        <Recipe {...page.recipe} />
                    </ScopeDataAttribute>
                )}
                {page.showFaq && <FaqList items={page.faq} />}
                <ArticleInfo lastUpdated={page._updatedAt} wineMagazineEdition={page.wineMagazineEdition} />
            </section>
            <RelatedPages {...page.relatedPages} />
        </article>
    </>
));

function Recipe({ name, recipeImage, servings, multistep, recipes, ingredients, richText }: Recipe) {
    return (
        <div className="recipe">
            <h2>{name}</h2>
            <CaptionedSanityImage
                image={recipeImage}
                widths={[320, 640, 1024, 1288]}
                sizes="(min-width: 1288) 1288px, 100vw"
                studioPath="recipeImage"
            />
            <p>Porsjoner: {servings}</p>
            {multistep ? (
                recipes?.map((r) => (
                    <ScopeDataAttribute key={r._key} path={[{ _key: r._key }]}>
                        <h3>{r.name}</h3>
                        <ul>
                            {r.ingredients?.map(({ _key, quantity, name, comment }) => (
                                <li key={_key}>
                                    {quantity} {name} {comment}
                                </li>
                            ))}
                        </ul>
                        <RichText value={r.richText} />
                    </ScopeDataAttribute>
                ))
            ) : (
                <>
                    <ul>
                        {ingredients?.map(({ _key, quantity, name, comment }) => (
                            <li key={_key}>
                                {quantity} {name} {comment}
                            </li>
                        ))}
                    </ul>
                    <RichText value={richText} />
                </>
            )}
        </div>
    );
}
