import type { SanityDocument } from '@sanity/client';
import Breadcrumbs from '../components/Breadcrumbs';
import { RelatedPages, RelatedPagesProps } from '../components/RelatedPages';
import TeaserCards, { teaserCardGroq, TeaserCardSchema } from '../components/TeaserCards';
import { taggedGridItemGroq } from '../grid/TaggedGridItem';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';

interface CategoryPage extends SanityDocument {
    _type: 'categoryPage';
    title: string;
    ingress: string;
    content: TeaserCardSchema[];
    relatedPages?: RelatedPagesProps;
}

export const categoryPage = queryable<CategoryPage>(groq`
    *[_type == "categoryPage" && _id == $id][0]{
        _id,
        _type,
        title,
        ingress,
        content[] ${teaserCardGroq},
        relatedContent[] {
            _key,
            ...(@ -> ${taggedGridItemGroq})
        }
    }
`);

export default SanityPage<CategoryPage>(({ page, breadcrumbs, dataAttribute }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <div className="category-page" data-sanity={dataAttribute?.('content')}>
            {page.content?.map(({ _key, ...props }) => (
                <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                    <TeaserCards {...props} />
                </ScopeDataAttribute>
            ))}
        </div>
        <RelatedPages {...page.relatedPages} />
    </article>
));
