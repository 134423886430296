import type { SanityDocument } from '@sanity/client';
import useOptimisticArray from '../../utils/useOptimisticArray';
import Breadcrumbs from '../components/Breadcrumbs';
import ContactCustomerService, { contactCustomerServiceGroq, ContactCustomerServiceSchema } from '../components/ContactCustomerService';
import StoreOpeningHours, { storeOpeningHoursGroq, StoreOpeningHoursSchema } from '../components/StoreOpeningHours';
import TeaserCards, { teaserCardGroq, TeaserCardSchema } from '../components/TeaserCards';
import { TaggedSanityDocument } from '../grid/Grid';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';

interface CustomerServiceCategoryPage extends SanityDocument {
    _type: 'customerServiceCategoryPage';
    title: string;
    ingress: string;
    content: (TeaserCardSchema | ContactCustomerServiceSchema | StoreOpeningHoursSchema)[];
    relatedContent: TaggedSanityDocument[];
}

export const customerServiceCategoryPage = queryable<CustomerServiceCategoryPage>(groq`
    *[_type == "customerServiceCategoryPage" && _id == $id][0]{
        _id,
        _type,
        title,
        ingress,
        content[] {
            _key,
            _type,
            _type == 'contentTeaser' => ${teaserCardGroq},
            _type == 'contactCustomerService' => ${contactCustomerServiceGroq},
            _type == 'storeOpeningHours' => ${storeOpeningHoursGroq}
        },
    }
`);

export default SanityPage<CustomerServiceCategoryPage>(({ page, breadcrumbs, dataAttribute }) => {
    const content = useOptimisticArray(page.content, 'content');
    return (
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
            </header>
            <div className="category-page" data-sanity={dataAttribute?.('content')}>
                {content?.map(({ _key, ...props }) => {
                    switch (props._type) {
                        case 'contentTeaser':
                            return (
                                <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                                    <TeaserCards {...props} />
                                </ScopeDataAttribute>
                            );
                        case 'contactCustomerService':
                            return (
                                <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                                    <ContactCustomerService {...props} />
                                </ScopeDataAttribute>
                            );
                        case 'storeOpeningHours':
                            return (
                                <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                                    <StoreOpeningHours {...props} />
                                </ScopeDataAttribute>
                            );
                        default:
                            return (
                                <div key={_key} style={{ display: 'none' }}>
                                    {(props as any)._type}
                                </div>
                            );
                    }
                })}
            </div>
        </article>
    );
});
