import { PortableTextBlock } from '@portabletext/react';
import { ComponentType } from 'react';
import { SanityPageDocument } from '../../layout/SanityHeaderFooterLayout';
import { AlphabetList } from '../components/AlphabetList';
import Breadcrumbs from '../components/Breadcrumbs';
import { Brochure, BrochureProps, brochuresGroq } from '../grid/Brochure';
import DefinitionItem, { definitionItemGroq, DefinitionItemProps } from '../grid/DefinitionItem';
import GrapeItem, { grapeItemGroq, GrapeItemProps } from '../grid/GrapeItem';
import { FilterableGrid } from '../grid/Grid';
import NewsArticleItem, { newsArticleGroq, NewsArticleProps } from '../grid/NewsArticleItem';
import SimpleGridItem, { SimpleGridItemProps } from '../grid/SimpleGridItem';
import TaggedGridItem, { TaggedGridItemDocument, taggedGridItemGroq, TaggedGridItemProps } from '../grid/TaggedGridItem';
import { WineCountryItem, wineCountryItemGroq, WineCountryItemProps } from '../grid/WineCountryItem';
import { WineMagazine, wineMagazineGroq, WineMagazineProps } from '../grid/WineMagazine';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';
import { RichText, richTextGroq } from '../richTextComponents';
import { salesNumbersPageListGroq } from './SalesNumberPage';

export interface ListPage extends SanityPageDocument {
    _type: 'listPage';
    listType: string;
    list: TaggedGridItemDocument[] | NewsArticleProps[] | WineMagazineProps[] | BrochureProps[] | GrapeItemProps[] | DefinitionItemProps[];
    richText: PortableTextBlock[];
    listTypeComponent?: 'simple' | 'tagged';
}

export const listPage = queryable<ListPage>(groq`
    *[_type == "listPage" && _id == $id][0]{
        _id,
        _type,
        hidden,
        listType,
        listTypeComponent,
        slug,
        title,
        subtitle,
        ingress,
        metadata,
        richText[] ${richTextGroq},
        'list': select(
            listType == 'wineMagazine' => *[_type == ^.listType]${wineMagazineGroq} | order(releaseNumber desc),
            listType == 'newsArticle' => *[_type == ^.listType]${newsArticleGroq} | order(firstPublished desc),
            listType == "brochure" => *[_type == ^.listType]${brochuresGroq} | order(title asc),
            listType == "grape" => *[_type == ^.listType]${grapeItemGroq} | order(name asc),
            listType == "definition" => *[_type == ^.listType]${definitionItemGroq} | order(name asc),
            listType == "vintageArticle" => *[_type == ^.listType]${taggedGridItemGroq} | order(title asc),
            listType == "salesNumbersPage" => *[_type == ^.listType]${salesNumbersPageListGroq} | order(year desc),
            listType == "wineCountryArticle" => *[_type == ^.listType]${wineCountryItemGroq} | order(title asc),
            listType == "subpages" => *[parent._ref == ^._id]${taggedGridItemGroq} | order(title asc),
            *[_type == ^.listType]${taggedGridItemGroq} | order(title asc)
        )
    }
`);

export default SanityPage<ListPage>(({ page, url, breadcrumbs }) => (
    <article className="sanity" lang={page.metadata?.language}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <header>
            <h1>{page.title}</h1>
            <p>{page.ingress}</p>
        </header>
        <section>
            <RichText value={page.richText} studioPath="richText" />
        </section>
        {page.subtitle && <h2 className="lined-heading">{page.subtitle}</h2>}
        {(page?.listType === 'grape' || page?.listType === 'definition') && (
            <AlphabetList list={page?.list?.map((item) => (item as { name: string }).name)} />
        )}
        {page.list?.length > 0 && (
            <FilterableGrid list={page.list} component={selectComponentType(page)} selectedTags={url.searchParams.getAll('tag')} />
        )}
    </article>
));

const selectComponentType = (page: ListPage): ComponentType<any> => {
    if (page.listType === 'subpages') {
        return page.listTypeComponent === 'simple' ? SimpleGridItem : TaggedGridItem;
    }

    return ListItem;
};

function ListItem(
    props:
        | TaggedGridItemProps
        | NewsArticleProps
        | WineMagazineProps
        | BrochureProps
        | GrapeItemProps
        | DefinitionItemProps
        | SimpleGridItemProps<'salesNumbersPage'>
        | WineCountryItemProps,
) {
    switch (props._type) {
        case 'newsArticle':
            return <NewsArticleItem {...props} />;
        case 'wineMagazine':
            return <WineMagazine {...props} />;
        case 'brochure':
            return <Brochure {...props} />;
        case 'grape':
            return <GrapeItem {...props} />;
        case 'definition':
            return <DefinitionItem {...props} />;
        case 'salesNumbersPage':
            return <SimpleGridItem {...props} />;
        case 'wineCountryArticle':
            return <WineCountryItem {...props} />;
        case 'vintageArticle':
            return <TaggedGridItem {...props} showIngress />;
        default:
            return <TaggedGridItem {...props} />;
    }
}

export function listPageIsDynamic(page: ListPage) {
    switch (page.listType) {
        case 'grape':
        case 'wineMagazine':
        case 'brochure':
        case 'definition':
            return false;
        default:
            return true;
    }
}
