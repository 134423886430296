import { JSXElementConstructor, PropsWithChildren, ReactElement, createContext, createElement, useContext } from 'react';
import { getComponent } from '../clientComponents';

const isServer = typeof window === 'undefined';

const ClientContext = createContext(false);

export function ClientRendering({ children, props }: PropsWithChildren<{ props?: any }>) {
    return (
        <ClientContext.Provider value={true}>
            {props && <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(props) }}></script>}
            {children}
        </ClientContext.Provider>
    );
}

export default function ClientReactRoot<As extends keyof JSX.IntrinsicElements = 'div'>({
    as,
    children,
    ...props
}: { as?: As; children: ReactElement<any, JSXElementConstructor<any>> } & JSX.IntrinsicElements[As]) {
    const alreadyClient = useContext(ClientContext);
    if (isServer && !alreadyClient) {
        return createElement(
            as ?? 'div',
            {
                ...props,
                'data-react-component': assertComponentDefined(children.type.name),
            },
            createElement(
                ClientRendering,
                {
                    props: children.props,
                },
                children,
            ),
        );
    } else {
        return createElement(as ?? 'div', props, createElement(ClientRendering, {}, children));
    }
}

function assertComponentDefined(name: string) {
    getComponent(name);
    return name;
}
