import type { StudioPathLike } from '@sanity/react-loader';
import { type CreateDataAttribute, type CreateDataAttributeProps } from '@sanity/visual-editing';
import { PropsWithChildren, createContext, useContext } from 'react';

type DataAttributeProps = CreateDataAttributeProps & {
    id: string;
    type: string;
};

export type DataSanity = CreateDataAttribute<DataAttributeProps> & { _id: string; path: StudioPathLike };

const DataAttributeContext = createContext<undefined | DataSanity>(undefined);

export default function useDataAttribute() {
    return useContext(DataAttributeContext);
}

export function ProvideDataAttribute({ value, children }: PropsWithChildren<{ value: DataSanity }>) {
    return <DataAttributeContext.Provider value={value}>{children}</DataAttributeContext.Provider>;
}

type ScopeProps =
    | {
          path: StudioPathLike;
          id?: never;
          type?: never;
      }
    | {
          path?: never;
          id: string;
          type: string;
      };

export function ScopeDataAttribute(props: PropsWithChildren<ScopeProps>) {
    const dataAttribute = useDataAttribute();

    if (!dataAttribute) return props.children;

    const value = typeof props.id === 'string' ? reset(dataAttribute, props) : scope(dataAttribute, props);
    return <DataAttributeContext.Provider value={value}>{props.children}</DataAttributeContext.Provider>;
}

function scope(dataAttribute: DataSanity, { path }: { path: StudioPathLike }) {
    const value = dataAttribute.scope(path) as DataSanity;
    value._id = dataAttribute._id;
    value.path = [...dataAttribute.path, ...path];
    return value;
}

function reset(dataAttribute: DataSanity, { id, type }: { id: string; type: string }) {
    const value = dataAttribute.combine({ id, type, path: [] }) as DataSanity;
    value._id = id;
    value.path = [];
    return value;
}

export function CombineDataAttribute({ children, ...props }: PropsWithChildren<DataAttributeProps>) {
    const dataAttribute = useDataAttribute();

    if (!dataAttribute) return children;

    const value = dataAttribute.combine(props) as DataSanity;
    value._id = props.id;
    return <DataAttributeContext.Provider value={value}>{children}</DataAttributeContext.Provider>;
}
