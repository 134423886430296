import { PortableText, PortableTextBlock, PortableTextBlockComponent, PortableTextComponents, toPlainText } from '@portabletext/react';
import { StudioPathLike } from '@sanity/react-loader';
import slugify from 'slugify';
import { imageWithMetadataGroq } from '../components/SanityImage';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import { DefinitioMark } from './DefinitionMark';
import { GrapeMark } from './GrapeMark';
import { Podcast, podcastGroq } from './Podcast';
import { ExternalLink } from './externalLink';
import { FactBox, factBoxGroq } from './factBox';
import { FaqElementWrapper, faqListGroq, FaqWrapper } from './faq';
import FileComponent, { fileComponentGroq, FileComponentHyperlink } from './fileComponent';
import { Float, floatGroq } from './float';
import Grid, { gridGroq } from './grid';
import { ImageWithMetadata } from './imageWithMetadata';
import { InternalLink } from './internalLink';
import { LinkCard, linkCardGroq } from './linkCard';
import { markDefsGroq } from './markDefsGroq';
import { Table, tableGroq } from './table';
import { TextWithAsset, textWithAssetGroq } from './textWithAsset';
import { TextboxWithColor, textboxWithColorGroq } from './textboxWithColor';
import { videoEmbedGroq, VimeoVideo } from './videoEmbed';

const marks = {
    link: ExternalLink,
    fileComponent: FileComponentHyperlink,
    internalLink: InternalLink,
    grapeMark: GrapeMark,
    definitionMark: DefinitioMark,
};

const types = {
    linkCard: LinkCard,
    fact: FactBox,
    imageWithMetadata: ImageWithMetadata,
    textboxWithColor: TextboxWithColor,
    videoEmbed: VimeoVideo,
    table: Table,
    textWithAsset: TextWithAsset,
    faq: FaqWrapper,
    faqItem: FaqElementWrapper,
    float: Float,
    grid: Grid,
    fileComponent: FileComponent,
    podcast: Podcast,
};

const block: Record<string, PortableTextBlockComponent> = {
    h2: ({ children, value }) => <h2 id={slugify(toPlainText(value))}>{children}</h2>,
};

const richTextComponents: Partial<PortableTextComponents> = {
    types,
    marks,
    block,
};

const simpleRichTextComponents: Partial<PortableTextComponents> = {
    marks,
};

export const richTextGroq = groq`{
    ...,
    _type == "fact" => @-> ${factBoxGroq},
    _type == "table" => ${tableGroq},
    _type == "textboxWithColor" => ${textboxWithColorGroq},
    _type == "imageWithMetadata" => ${imageWithMetadataGroq},
    _type == "linkCard" => ${linkCardGroq},
    _type == "videoEmbed" => ${videoEmbedGroq},
    _type == "textWithAsset" => @-> ${textWithAssetGroq},
    _type == "faq" => @-> ${faqListGroq},
    _type == "float" => ${floatGroq},
    _type == "grid" => ${gridGroq},
    _type == "fileComponent" => @-> ${fileComponentGroq},
    _type == "podcast" => @-> ${podcastGroq},
    markDefs[] ${markDefsGroq},
}`;

export function SimpleRichText({ value }: { value: PortableTextBlock }) {
    return <PortableText value={value} components={simpleRichTextComponents} />;
}

export function RichText({ value, studioPath = 'richText' }: { value: PortableTextBlock[]; studioPath?: StudioPathLike }) {
    return (
        <ScopeDataAttribute path={studioPath}>
            <PortableText value={value} components={richTextComponents} />
        </ScopeDataAttribute>
    );
}
