import { createElement } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import skyra from './analytics/skyra';
import { getComponent } from './clientComponents';
import GlobalContextProvider from './generic/GlobalContextProvider';
import detectSanityPresentationMode, { lazyLoadVisualEditing } from './sanity/infrastructure/detectSanityPresentationMode';
import { deleteTrackingCookies } from './utils/cookies';
import polyfillTargetInsideDetailsElement from './utils/polyfillTargetInsideDetailsElement';

/**
 * This is run on page load. It will look for html elements with data-react-component="Something" and
 * apply that react component to the element.
 *
 * If the first child element is a script[type="application/json"] then it will use the json content as
 * the props for the component.
 *
 * It will try to rehydrate the element, assuming that it has been prerendered on the server.
 */
document.addEventListener('DOMContentLoaded', () => {
    function getProps(elm: HTMLElement) {
        if (elm.firstElementChild instanceof HTMLScriptElement) {
            if (elm.firstElementChild.getAttribute('type') === 'application/json') {
                const props = JSON.parse(elm.firstElementChild.textContent ?? '{}');
                elm.removeChild(elm.firstElementChild);
                return props;
            }
        }
        return {};
    }

    document.querySelectorAll<HTMLElement>('[data-react-component]').forEach(async (elm) => {
        // Ignore this island if it is inside another island (react has already been applied to this subtree)
        if (elm.parentElement?.closest('[data-react-component]')) return;

        const componentName = elm.dataset['reactComponent'];
        if (!componentName) throw new Error('Missing value in data-react-component');

        // Get the react component by name
        const component = getComponent(componentName);

        // Get the props from the first child element that is a script tag with type application/json
        const props = getProps(elm);

        // Wrap the component with the GlobalContextProvider, which provides all the useful contexts
        // that should be available everywhere.
        const element = createElement(GlobalContextProvider, {}, createElement(component, props));

        // Rehydrate the element, unless it is empty
        if (elm.childElementCount === 0) {
            createRoot(elm).render(element);
        } else {
            hydrateRoot(elm, element);
        }
    });

    polyfillTargetInsideDetailsElement();

    if (document.cookie.includes('vmp-consent=true')) {
        skyra();
    } else {
        deleteTrackingCookies();
    }

    detectSanityPresentationMode(lazyLoadVisualEditing);
});
