import { Grid } from '../grid/Grid';
import groq from '../infrastructure/groq';
import { SalesNumbersProps } from '../page/SalesNumberPage';
import FileComponent, { FileComponentProps, fileComponentGroq } from './fileComponent';
import * as styles from './richText.module.less';
type GridProps = {
    name: string;
    items: (FileComponentProps | SalesNumbersProps)[];
};

export const gridGroq = groq`{
    name,
    items[]-> {
        _type,
        _id,
        _updatedAt,
        _type == "fileComponent" => ${fileComponentGroq}
    },
}`;

function GridItem(value: FileComponentProps | SalesNumbersProps) {
    const type = value._type;
    switch (type) {
        case 'fileComponent':
            return <FileComponent value={value} />;
        case 'salesNumbersFile':
            return <FileComponent value={value} category="Salgstall" />;
        default:
            return <span style={{ display: 'none' }}>Unknown grid item {type}</span>;
    }
}

export default function GridBlock({ value }: { value: GridProps }) {
    return (
        <div className={styles.grid}>
            {value.name && <h2 className="lined-heading">{value.name}</h2>}
            <Grid items={value.items} component={GridItem} autoFit />
        </div>
    );
}
