import type { StudioPathLike } from '@sanity/react-loader';
import { useOptimistic } from '@sanity/visual-editing';
import useDataAttribute from '../sanity/infrastructure/DataAttributeContext';
import type { Keyed } from '../sanity/types';

function followPath(object: any, path: StudioPathLike) {
    if (typeof path === 'string') {
        return object?.[path];
    }

    for (const segment of path) {
        if (Array.isArray(segment)) {
            throw new Error('Not implemented keyed segment yet');
        } else if (typeof segment === 'string' || typeof segment === 'number') {
            object = object?.[segment];
        } else if (typeof segment === 'object' && segment._key) {
            object = object.find((o: any) => o._key === segment._key);
        } else {
            throw new Error('uknown segment type ' + segment);
        }
    }
    return object;
}

export default function useOptimisticArray<T>(
    list: Keyed<T>[],
    path: StudioPathLike,
    context: { _id: string; path: StudioPathLike } | undefined = useDataAttribute(),
) {
    return useOptimistic(
        list,
        context
            ? (state, action) => {
                  if (action.id !== context?._id) return state;

                  if (!Array.isArray(state)) return state;

                  const pathAsArray = Array.isArray(path) ? path : [path];

                  const newValue = followPath(
                      action.document,
                      typeof context.path === 'string' ? [context.path, ...pathAsArray] : context.path.concat(...pathAsArray),
                  );

                  if (Array.isArray(newValue)) {
                      return newValue.map((item: any) => state?.find((s: any) => s._key === item?._key) || item);
                  }

                  return state;
              }
            : (state) => state,
    );
}
