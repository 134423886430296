import { Grid } from '../grid/Grid';
import TaggedGridItem, { taggedGridItemGroq, TaggedGridItemProps } from '../grid/TaggedGridItem';
import groq from '../infrastructure/groq';

export type RelatedPagesProps = {
    title?: string;
    relatedPages?: TaggedGridItemProps[];
};

export function RelatedPages({ title, relatedPages }: RelatedPagesProps = {}) {
    if (!relatedPages || relatedPages.length === 0) return null;
    return (
        <div className="end-of-article-grid">
            {title && <h2 className="lined-heading">{title}</h2>}
            <Grid items={relatedPages} component={CategorizedGridItem} autoFit />
        </div>
    );
}

function CategorizedGridItem(props: TaggedGridItemProps) {
    return <TaggedGridItem {...props} showCategory />;
}

export const relatedPagesGroq = groq`{
    title,
    relatedPages[]->${taggedGridItemGroq}
}`;
