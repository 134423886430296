import type { SanityDocument } from '@sanity/client';
import useOptimisticArray from '../../utils/useOptimisticArray';
import Breadcrumbs from '../components/Breadcrumbs';
import { ContactCard } from '../components/ContactCard';
import TeaserCards, { teaserCardGroq, TeaserCardSchema } from '../components/TeaserCards';
import { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq, { queryable } from '../infrastructure/groq';
import SanityPage from '../infrastructure/SanityPage';

interface Pressroom extends SanityDocument {
    _type: 'pressroom';
    title: string;
    ingress: string;
    contactPage?: {
        path: string;
    };
    contact?: {
        name: string;
        phone: string;
        email: string;
    };
    pressContacts?: {
        _key: string;
        contact?: {
            name?: string;
            role?: string;
        };
        overrideRole?: string;
        boldTitle: boolean;
    }[];
    content: TeaserCardSchema[];
}

export const pressroom = queryable<Pressroom>(groq`
    *[_type == "pressroom" && _id == $id][0]{
        _id,
        _type,
        title,
        ingress,
        contactPage,
        contact -> {
            name,
            phone,
            email
        },
        pressContacts[] {
            _key,
            contact -> {
                name,
                role
            },
            overrideRole,
            boldTitle
        },
        content[] ${teaserCardGroq}
    }
`);

export default SanityPage<Pressroom>(({ page, breadcrumbs, dataAttribute }) => {
    const content = useOptimisticArray(page.content, 'content');
    return (
        <article className="sanity" lang={page.metadata?.language}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <header>
                <h1>{page.title}</h1>
                <p>{page.ingress}</p>
            </header>
            <section className="pressroom">
                <ContactCard {...page.contact} />
                <div className="presscontacts">
                    <h2>Pressekontakter</h2>
                    <ul>
                        {page.pressContacts
                            ?.filter((p) => p.boldTitle)
                            .map(({ _key, contact, overrideRole }) => (
                                <li key={_key}>
                                    <strong>{overrideRole ?? contact?.role}:</strong> {contact?.name}
                                </li>
                            ))}
                    </ul>
                    <ul>
                        {page.pressContacts
                            ?.filter((p) => !p.boldTitle)
                            .map(({ _key, contact, overrideRole }) => (
                                <li key={_key}>
                                    {overrideRole ?? contact?.role}: {contact?.name}
                                </li>
                            ))}
                    </ul>
                </div>
            </section>
            <div className="category-page" data-sanity={dataAttribute?.('content')}>
                {content?.map(({ _key, ...props }) => (
                    <ScopeDataAttribute key={_key} path={['content', { _key }]}>
                        <TeaserCards {...props} />
                    </ScopeDataAttribute>
                ))}
            </div>
        </article>
    );
});
