import { PortableTextComponentProps } from '@portabletext/react';
import { FaqElement, FaqGroup, FaqItem, faqListGroq } from '../components/Faq';

export { faqListGroq };

export interface FaqProps {
    title?: string;
    showTitle?: boolean;
    faqItems: FaqItem[];
}

export function FaqWrapper({ value }: PortableTextComponentProps<FaqProps>) {
    return <FaqGroup title={value.showTitle ? value.title : undefined} faqItems={value.faqItems} />;
}

export function FaqElementWrapper({ value }: PortableTextComponentProps<FaqItem>) {
    return <FaqElement question={value.question} answer={value.answer} />;
}
