import groq from '../infrastructure/groq';
import { markDefsGroq } from '../richTextComponents/markDefsGroq';
import { Accordion, AccordionGroup } from './Accordion';

export interface Faq {
    _type: 'faq';
    title?: string;
    faqItems?: FaqItem[];
}

export interface FaqItem {
    _type: 'faqItem';
    _id: string;
    question: string;
    answer: any;
}

export const faqItemGroq = groq`{
    _id,
    _type,
    _key,
    question,
    answer[] {
        ...,
        markDefs[] ${markDefsGroq}
    }
}`;

export type FaqArray = (Faq | FaqItem)[];

export const faqListGroq = groq` {
    _id,
    _key,
    _type,
    _type == "faqItem" => ${faqItemGroq},
    _type == "faq" => {
        title,
        showTitle,
        faqItems[] -> ${faqItemGroq},
    }
}`;

/**
 * Show a list of questions and answers. The items can either be
 * individual questions/answers or a group of questions/answers.
 * The group is flattened into the list. The group title is ignored
 */
export function FaqList({ items }: { items?: FaqArray }) {
    return (
        <AccordionGroup
            items={items
                ?.filter(Boolean)
                .flatMap((i) => (i._type === 'faq' ? (i.faqItems ?? []) : [i]))
                .map(({ _id, question, answer }) => ({ _key: _id, title: question, text: answer }))}
        />
    );
}

export function FaqGroup({ title, faqItems }: Omit<Faq, '_type'> = {}) {
    return (
        <AccordionGroup
            title={title}
            items={faqItems?.filter(Boolean).map(({ _id, question, answer }) => ({ _key: _id, title: question, text: answer }))}
        />
    );
}

export function FaqElement({ question, answer }: { question: string; answer: any }) {
    return <Accordion title={question} text={answer} />;
}
