import { SanityDocument } from '@sanity/client';
import groq from '../infrastructure/groq';
import { richTextGroq, SimpleRichText } from '../richTextComponents';
import Grape from './Grape';
import * as style from './Grape.module.less';

export const grapeItemGroq = groq`{
    _id,
    _type,
    "slug": slug.current,
    name,
    summary,
    description[] ${richTextGroq},
    'synonyms': *[_type == 'grape' && mainEntry._ref == ^._id].name,
    grapeType,
    mainEntry -> {
        'slug': slug.current,
        name
    }
}`;

export interface GrapeItemProps extends SanityDocument {
    _type: 'grape';
    slug: string;
    name: string;
    summary: string;
    description: any;
    synonyms: { name?: string }[];
    grapeType: 'green' | 'blue';
    mainEntry?: { name: string; slug: string };
}

export default function GrapeItem({ name, summary, description, synonyms, grapeType, slug, mainEntry }: GrapeItemProps) {
    return (
        <div className={style.grape} id={name[0]?.toLowerCase()}>
            <details id={slug}>
                <summary>
                    <Grape name={name} summary={summary} grapeType={grapeType} />
                    {mainEntry && (
                        <p>
                            <span>Se: </span>
                            <a href={`#${mainEntry.slug}`}>{mainEntry.name}</a>
                        </p>
                    )}
                </summary>
                <SimpleRichText value={description}></SimpleRichText>
            </details>
            {synonyms?.length > 0 && (
                <p className={style.synonyms}>
                    <span>Synonymer:</span>
                    {synonyms
                        ?.filter(Boolean)
                        .map((t) => (typeof t === 'string' ? t : t.name))
                        .map((t) => <strong key={t}>{t}</strong>)}
                </p>
            )}
        </div>
    );
}
