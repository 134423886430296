import Icon from '../../generic/Icon';
import useDataAttribute, { ScopeDataAttribute } from '../infrastructure/DataAttributeContext';
import groq from '../infrastructure/groq';
import * as style from './ContactCustomerService.module.less';
import { FaqArray, FaqList, faqListGroq } from './Faq';
import OpeningHours, { OpeningHoursProps } from './OpeningHours';
import SanityImage, { ImageWithMetadata, imageWithMetadataGroq } from './SanityImage';

export interface ContactCustomerServiceSchema {
    _key: string;
    _type: 'contactCustomerService';
    title: string;
    ingress: string;
    image: ImageWithMetadata;
    contact: {
        phone: string;
        email: string;
    };
    chatInfo: string;
    openingHours: OpeningHoursProps;
    faq: FaqArray;
}

export interface ContactCustomerServiceProps {
    _type: 'contactCustomerService';
    title: string;
    ingress: string;
    image: ImageWithMetadata;
    contact: {
        phone: string;
        email: string;
    };
    chatInfo: string;
    openingHours: OpeningHoursProps;
    faq: FaqArray;
}

export const contactCustomerServiceGroq = groq`{
    _type,
    _key,
    title,
    ingress,
    contact -> {
        phone,
        email
    },
    chatInfo,
    image ${imageWithMetadataGroq},
    openingHours -> {
        ...
    },
    faq[] -> ${faqListGroq}
}`;

export default function ContactCustomerService({ title, ingress, openingHours, contact, chatInfo, image, faq }: ContactCustomerServiceProps) {
    const dataAttr = useDataAttribute();
    return (
        <div data-sanity={dataAttr?.([])} className={style.container}>
            <div className={style.top}>
                <SanityImage image={image} widths={[140, 2 * 140]} sizes="140px" aspectRatio={1} style={{ background: 'none' }} />
                <h2>{title}</h2>
                <p>{ingress}</p>
            </div>
            <div className={style.contact}>
                <a href={`tel:${contact.phone}`}>
                    <Icon className="icon-phone" />
                    {contact.phone}
                </a>
                <a href={`mailto:${contact.email}`}>
                    <Icon className="icon-email" />
                    {contact.email}
                </a>
                <p>
                    <Icon className="icon-chat" renderWithSpan />
                    {chatInfo}
                </p>
            </div>
            <div className={style.openingHours}>
                <ScopeDataAttribute path="openingHours">
                    <OpeningHours {...openingHours} />
                </ScopeDataAttribute>
            </div>
            <div className={style.faq}>
                <FaqList items={faq} />
            </div>
        </div>
    );
}
