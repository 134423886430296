export function getCookieByPartialName(name: string): string {
    if (typeof document === 'undefined') return '';
    return (
        document.cookie
            ?.split(';')
            .find((cookie) => cookie.includes(name))
            ?.split('=')[0]
            ?.trim() || ''
    );
}

export function deleteCookie(cookieName: string) {
    const parts = document.location.host.split('.');

    // The cookie might have been set on a base domain, so we need to try upwards until it is gone
    while (parts.length > 0 && document.cookie.includes(`${cookieName}=`)) {
        document.cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${parts.join('.')}`;
        parts.shift();
    }
}

export function deleteTrackingCookies() {
    const gaCookieName = getCookieByPartialName('_ga_');
    deleteCookie(gaCookieName);
    deleteCookie('_ga');
    deleteCookie('skyra.state');
}

export function setConsentCookie(consented: boolean) {
    const futureDate = new Date().getTime() + 90 * 24 * 60 * 60 * 1000;
    document.cookie = `vmp-consent=${consented}; Path=/; Expires=${new Date(futureDate)}`;
}
